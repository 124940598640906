import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7db68b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      class: "video",
      width: $props.width,
      height: $props.height,
      src: `https://www.youtube.com/embed/${$props.videoId}`,
      title: " 원어민 | 영어회화 | 집에서 | 영어공부 | (4:5)",
      frameborder: "0",
      allow: "accelerometer; autoplay; \n            clipboard-write; encrypted-media;\n            gyroscope; picture-in-picture",
      allowfullscreen: ""
    }, null, 8 /* PROPS */, _hoisted_1)
  ]))
}