
export default {
  name: 'VideoCard',
  props: {
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
      default: 175,
    },
    videoId: {
      type: String,
      required: true,
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
};
