<template>
  <div class="option">
    <div class="option__choice">
      <p>
        선택필수
        <span>*</span>
      </p>
      <div class="select__box" :class="{ selected: choicedOptionData?.title }" @click="toggleCollapse">
        <div class="name">
          <span>{{ parserTitle(choicedOptionData?.title, choiceIndex) }}</span>
          <span class="badge-red" v-if="choiceRecommend">추천</span>
        </div>
        <img v-if="!isOpenCollapse" v-img-lazy-loading :data-url="imgUrls.ic_arrow_down" class="icon" />
        <img v-if="isOpenCollapse" v-img-lazy-loading :data-url="imgUrls.ic_arrow_up" class="icon" />
      </div>
      <ul class="options" v-if="isOpenCollapse">
        <li v-for="(option, index) in Plans"
          @click="sendChoicedOptionId(option.cp_idx, index, option.isRecommend)"
          :class="{ selected: copyChoicedOptionIdx === option.cp_idx }" :key="option.cp_idx">
          <div class="name">
            {{ index + 1 }}. {{ replacedTitle(option.title) }}
            <span class="badge-red" v-if="option.isRecommend">추천</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="option__selected">
      <p>최종 선택 내용</p>
      <div class="option__selected__info">
        <div class="info__price">
          <div class="price__type">수강권</div>
          <div class="price__detail">
            <div class="detail__name">{{ choicedOptionData?.title }}</div>
            <div class="detail__price">
              <span class="discountPer">{{ choicedOptionData?.discount }}%</span>
              {{ priceComma(choicedOptionData?.money) }}원
            </div>
            <div class="price__original">{{ priceComma(choicedOptionData?.origin_money) }}</div>
          </div>
        </div>
        <div class="info__price">
          <div class="price__type">할인가</div>
          <div class="price__detail">
            <div class="detail__name">{{ currentOptionInstallment }}개월 할부 시</div>
            <div class="detail__price detail__price--discounted">
              월 {{ priceComma(choicedOptionData?.moneyPerMonth) }}원
            </div>
          </div>
        </div>
        <ul class="info__list">
          <li v-for="(optionServe, index) in currentOptionServes" :key="index">
            {{ optionServe }}
          </li>
        </ul>
        <div class="info__comment">
          {{ thisWeekStart }}~{{ thisWeekEnd }} 구매자는
          <br />
          {{ nextWeekStart }}부터 수업 시작이 가능합니다.
          <p>{{ nextWeekStart }}이전에 수강권 '지금 바로 시작하기'를 누르지 않도록 유의해주세요.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import vClickOutside from 'click-outside-vue3';
import promotionMixins from '../../../common/mixins/promotion';
import vImageLazyLoading from '../../../common/directives/ImgLazyLoading';

export default {
  name: 'Pr90DaysMiddleOptions',
  mixins: [promotionMixins],
  props: {
    promotionData: Object,
    choicedOptionIdx: Number,
    choicedOptionData: Object,
    isRecommendIdx: Number,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  data() {
    return {
      copyChoicedOptionIdx: this.choicedOptionIdx,
      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',
      currentOptionServes: [],
      currentOptionInstallment: '12',
      choiceTitle: '수강권을 선택해주세요.',
      choiceIndex: 0,
      choiceRecommend: false,
      imgUrls: {
        ic_arrow_down: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_16px.png',
        ic_arrow_up: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_up_16px.png',
      },
      isOpenCollapse: false,
    };
  },
  mounted() {
    this.setPurchaeDateInfo();
    this.setCurrentOptionServes();
    this.choiceIndex = this.isRecommendIdx
  },
  beforeUpdate() {
    this.copyChoicedOptionIdx = this.choicedOptionIdx;
    this.choiceRecommend = this.choicedOptionData?.isRecommend;
  },
  methods: {
    sendChoicedOptionId(option, index, recommend) {
      switch (option) {
        case 6904:
          this.trackingUserAction('90package_mid_footer_select90_Global');
          break;
        case 6903:
          this.trackingUserAction('90package_mid_footer_select60_Global');
          break;
        case 6902:
          this.trackingUserAction('90package_mid_footer_select180_Global');
          break;
        case 6916:
          this.trackingUserAction('90package_mid_footer_select90_Native');
          break;
        case 6917:
          this.trackingUserAction('90package_mid_footer_select60_Native');
          break;
        case 6905:
          this.trackingUserAction('90package_mid_footer_select180_Native');
          break;
        default:
          break;
      }
      this.copyChoicedOptionIdx = option;
      this.setCurrentOptionServes();
      this.$emit('update', option);
      this.choiceIndex = index;
      console.log(this.choiceIndex)
      this.choiceRecommend = recommend
      this.isOpenCollapse = false;
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
    replacedTitle(title: string) {
      if (title) {
        const newTitle = title.replace('[중급전용]', '');
        return newTitle;
      }
      return title;
    },
    // 기존 라디오 박스 형태가 UI의 문제로 인해 드롭박스 형태로 변경
    // 이 과정에서 현재 페이지는 [중급전용] 페이지가 확실하므로 [중급전용] 문구를 삭제하는 함수 생성
    // 이후 작업에서 해당 문구가 사라지거나 다른 문구를 삭제해야하는 상황이 생길시 수정 가능
    parserTitle(title: string, num: number) {
      console.log(title)
      if (!title) {
        return "수강권이 없습니다"
      }
      const newTitle = title.replace('[중급전용]', '');
      return `${num + 1}. ${newTitle}`
    },
    toggleCollapse(): void {
      this.isOpenCollapse = !this.isOpenCollapse;
    },
    setCurrentOptionServes() {
      switch (this.copyChoicedOptionIdx) {
        case 6904:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 주 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6903:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 60회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 30회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6902:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6916:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 주 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6917:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 60회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 30회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6905:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        default:
          break;
      }
    },
  },
  computed: {
    Plans() {
      const Plans = [...this.promotionData.plans]
      return Plans;
    },
  },
};
</script>

<style lang="scss" scoped>
.select__box {
  display: flex;
  background: #f3f3f3;
  margin: 0 auto;
  text-align: left;
  padding: 14px;
  font-size: 13px;
  color: #999999;
  background: #FFFFFF;
  border: 1px solid #D6D3DD;
  cursor: pointer;

  & .name {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &.selected {
    border: 1px solid #5D04D9;
    color: #5D04D9;
    font-weight: 700;
  }

  & .badge-red {
    margin-left: 6px;
  }

  @media (max-width:380px) {
    & .badge-red {
      margin-left: 6px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    float: right;
    vertical-align: text-top;
  }
}


.badge-red {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  color: #fff;
  border-radius: 10vw;
  background: #ff1212;
  font-size: 10px;
  height: 16px;
  box-sizing: border-box;
}

.option {
  position: relative;

  & .option__choice {
    position: absolute;
    width: 100%;
    background-color: #ffffff;

    & p {
      font-size: 12px;
      font-weight: 700;
      padding-bottom: 10px;
      color: #85808d;

      & span {
        font-size: 12px;
        font-weight: 700;
        padding: 21px 0 10px;
        color: #ff1212;
      }
    }

    & ul {
      box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.15);

      & li {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        border-left: 1px solid #d6d3dd;
        border-right: 1px solid #d6d3dd;
        border-bottom: 1px solid #d6d3dd;
        padding: 0 14px;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;

        &.selected {
          border: 1px solid #5D04D9;
          color: #5D04D9;
        }

        & .name {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & .badge-red {
          margin-left: 6px;
        }
      }
    }
  }

  & .option__selected {
    margin-top: 72px;
    padding: 26px 0 26px;
    font-size: 12px;
    font-weight: 700;
    color: #85808d;

    & .option__selected__info {
      background-color: #f8f8fa;
      border: 1px solid #f1f0f5;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 24px 30px 20px;
      margin-top: 10px;

      & .info__price {
        height: 90px;
        padding-top: 15px;
        border-bottom: 1px solid #e8e6ed;
        display: flex;
        justify-content: space-between;

        & .price__type {
          font-weight: bold;
          font-size: 14px;
          color: #121214;
          letter-spacing: -0.5px;
          width: 15%;
        }

        & .price__detail {
          text-align: end;

          & .detail__name {
            font-weight: 400;
            font-size: 12px;
            color: #85808d;
            width: 230px;
          }

          & .detail__price {
            display: flex;
            justify-content: end;
            align-items: center;
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-top: 7px;

            &.detail__price--discounted {
              color: #ff1212;
            }

            & .discountPer {
              font-size: 14px;
              color: #ff1212;
              margin-right: 10px;
            }
          }

          & .price__original {
            font-weight: 400;
            font-size: 12px;
            color: #d6d3dd;
            text-decoration: line-through;
            margin-top: 7px;
          }
        }
      }

      & .info__list {
        list-style-type: disc;
        margin-top: 10px;
        padding-left: 20px;
        color: #333333;
        line-height: 18px;

        & li {
          vertical-align: middle;
        }

        & li+li {
          margin-top: 4px;
        }
      }

      & .info__comment {
        margin-top: 22px;
        line-height: 18px;
        color: #3f3f3f;

        & p {
          margin-top: 6px;
          color: #a3a3a3;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
