import { render } from "./Pr90daysMiddle.vue?vue&type=template&id=3127df28&scoped=true"
import script from "./Pr90daysMiddle.vue?vue&type=script&lang=ts"
export * from "./Pr90daysMiddle.vue?vue&type=script&lang=ts"

import "../../assets/newPromotions.scss?vue&type=style&index=0&id=3127df28&lang=scss&scoped=true"
import "./Pr90daysMiddle.vue?vue&type=style&index=1&id=3127df28&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3127df28"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3127df28"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3127df28', script)) {
    api.reload('3127df28', script)
  }
  
  module.hot.accept("./Pr90daysMiddle.vue?vue&type=template&id=3127df28&scoped=true", () => {
    api.rerender('3127df28', render)
  })

}

script.__file = "src/page/promotion/Pr90daysMiddle.vue"

export default script