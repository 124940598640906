<template>
  <!-- app nav -->
  <AppNav v-if="appView">실력 향상 보증! 작심 패키지</AppNav>

  <div class="promotion" :style="{ 'padding-top': appPadding }">
    <div
      v-if="isPurchaseClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal--purchase" v-click-outside="closePurchaseModal">
        <div class="common-modal__content">
          <div class="common-modal__description" v-html="purchaseErrorMessage"></div>
          <div class="common-modal__description--small">
            {{ purchaseErrorSubMessage }}
          </div>
        </div>

        <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'level'">
          <button type="button" class="common-modal__button" @click="closePurchaseModal">취소</button>
          <button type="button" class="common-modal__button" @click="goPurchasePage">확인</button>
        </div>
        <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'hasTicket'">
          <button type="button" class="common-modal__button" @click="closePurchaseModal">확인</button>
        </div>
      </div>
    </div>
    <div class="promotion__banner">
      <div class="slides">
        <swiper
          :loop="true"
          :slidesPerView="1"
          :speed="500"
          :autoplay="{ dalay: '200', disableOnInteraction: false }"
          :pagination="{ clickable: true }"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
        >
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_1" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_2" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_3" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_4" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_5" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_6" />
          </swiper-slide>
          <swiper-slide>
            <img :src="imgUrls.pt90_banner_img_8" />
          </swiper-slide>
        </swiper>
      </div>
      <img :src="imgUrls.pt90_banner_desc" />

      <!-- <CouponBox /> -->

      <!-- 쿠폰 다운로드 영역 -->
      <!-- <div class="coupon-download-contaienr">
        <button class="coupon-download-box button" @click="showCouponModal" type="button">
          <div class="box">
            <div class="left-img" />
            <div class="text">
              <p class="desc">튜터링 할인 페스타</p>
              <p class="title">할인쿠폰 4종</p>
            </div>
          </div>
          <div class="download-img" />
          <div class="download-done">
              다운
              <br />
              완료
            </div>
        </button>
        <p class="desc">자세한 쿠폰 사용 안내는 수강권 유의사항을 참고해 주세요.</p>
      </div> -->

      <div v-if="isCouponClicked">
        <div class="layer_comm">
          <PopupToast :isPopup="isPopup"><p v-html="cuponPopupMessage"></p></PopupToast>
          <div class="dimmed"></div>
          <div class="inner_wrapper">
            <div class="inner_layer">
              <div class="coupon_title">즉시 사용 가능한 추가 할인 쿠폰</div>
              <div class="coupon__container" id="togo1">
                <div
                  v-for="(coupon, index) in couponData?.coupon?.data?.coupons"
                  :key="index"
                  @click="!hasCouponIdxArray(coupon.idx) && !coupon.hasCoupon && postCouponDownload(coupon.idx, index)"
                  :class="[
                    { 'coupon coupon100000': coupon.dc_money === 100000 },
                    { 'coupon coupon50000': coupon.dc_money === 50000 },
                    { 'coupon coupon30000': coupon.dc_money === 30000 },
                    { 'coupon coupon10000': coupon.dc_money === 10000 },
                    { 'is-download': coupon.hasCoupon || hasCouponIdxArray(coupon.idx) },
                  ]"
                ></div>
                <button v-if="hasCouponIdx.length < 4" class="coupon_button" @click="postCouponDownloadAll()">
                  쿠폰 한 번에 받기
                </button>
                <button
                  v-if="hasCouponIdx.length >= 4"
                  class="coupon_button_enabled"
                  @click="postCouponDownloadAll()"
                  disabled
                >
                  다운로드 완료
                </button>
              </div>
            </div>
            <div class="coupon_notice">
              <div class="notice_title">
                <p>쿠폰 유의 사항</p>
              </div>
              <div class="notice_desc">
                <p>· 쿠폰 다운로드 후 사용이 가능합니다.</p>
                <p>· 일부 수강권에서는 쿠폰 사용이 불가합니다.</p>
              </div>
            </div>
            <div class="extra_wrapper">
              <button class="extra_button" @click="showCouponModal">
                <img
                  class="close_icon"
                  src="//res.tutoring.co.kr/res/images/img/plans/ic_cp_modal_close.png"
                  alt="close"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="why">
      <div class="inner">
        <img :src="imgUrls.pt90_mid_01" />
      </div>
    </section>
    <section class="correct">
      <div class="inner">
        <img :src="imgUrls.pt90_mid_02" alt="영어를 잘하는 방법" />
      </div>
    </section>
    <section class="banner__course">
      <div class="inner">
        <img class="img1" :src="imgUrls.pt90_mid_03" />
        <div class="blur"></div>
      </div>
    </section>
    <section class="courses__container">
      <div class="course__title">
        <div class="course__tab" :class="{ fixed: tabsFix }" :style="{ top: tabsFixTop }">
          <li
            class="tab1"
            :class="{ active: curTab === 1 }"
            v-scroll-to="{
              el: '#course__info',
              duration: 200,
              easing: 'linear',
              offset: -courseTabChoiceSectionOffset(),
              x: false,
              y: true,
              onStart: () => {
                onChangeTab(1);
              },
            }"
          >
            <p class="tabs">커리큘럼</p>
          </li>
          <li
            class="tab2"
            :class="{ active: curTab === 2 }"
            v-scroll-to="{
              el: '#course__complete',
              duration: 200,
              easing: 'linear',
              offset: -courseTabChoiceSectionOffset(),
              x: false,
              y: true,
              onStart: () => {
                onChangeTab(2);
              },
            }"
          >
            <p class="tabs">수료 조건</p>
          </li>
          <li
            class="tab3"
            :class="{ active: curTab === 3 }"
            v-scroll-to="{
              el: '#course__price',
              duration: 200,
              easing: 'linear',
              offset: -courseTabChoiceSectionOffset(),
              x: false,
              y: true,
              onStart: () => {
                onChangeTab(3);
              },
            }"
          >
            <p class="tabs">가격 안내</p>
          </li>
        </div>
      </div>
      <div class="course__info" id="course__info">
        <!-- 커리큘럼 -->
        <div class="inner">
          <img :src="imgUrls.pt90_cur_title" class="main_img" />
          <div class="course__step">
            <img :src="imgUrls.pt90_cur_subtitle_1" class="subtitle" />
            <div class="course__special">
              <div class="course__slide__wrapper">
                <div class="course__slide">
                  <swiper
                    :slides-per-view="9"
                    :space-between="18"
                    :centeredSlides="true"
                    :slideToClickedSlide="true"
                    style="min-width: 2502px !important"
                  >
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course1"
                        :title="courseInfos.course1.title"
                        :paragraph="courseInfos.course1.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course2"
                        :title="courseInfos.course2.title"
                        :paragraph="courseInfos.course2.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course3"
                        :title="courseInfos.course3.title"
                        :paragraph="courseInfos.course3.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course4"
                        :title="courseInfos.course4.title"
                        :paragraph="courseInfos.course4.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course5"
                        :title="courseInfos.course5.title"
                        :paragraph="courseInfos.course5.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course6"
                        :title="courseInfos.course6.title"
                        :paragraph="courseInfos.course6.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course7"
                        :title="courseInfos.course7.title"
                        :paragraph="courseInfos.course7.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course8"
                        :title="courseInfos.course8.title"
                        :paragraph="courseInfos.course8.paragraph"
                      />
                    </swiper-slide>
                    <swiper-slide class="slide__card">
                      <Accordion
                        v-img-lazy-loading
                        :imgUrl="imgUrls.course9"
                        :title="courseInfos.course9.title"
                        :paragraph="courseInfos.course9.paragraph"
                      />
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
          <div class="course__step">
            <img :src="imgUrls.pt90_cur_subtitle_2" class="subtitle" />
            <img :src="imgUrls.pt90_cur_img_2" class="subimg" />
          </div>
          <div class="course__step">
            <img :src="imgUrls.pt90_cur_subtitle_3" class="subtitle" />
            <img :src="imgUrls.pt90_cur_img_3" class="subimg2" />
          </div>
          <div class="course__step">
            <img :src="imgUrls.pt90_cur_subtitle_4" class="subtitle" />
            <video
              class="subimg2"
              loop
              playsinline
              autoplay
              muted
              src="https://res.tutoring.co.kr/res/images/img/plans/challenge/img_special_04.mp4"
            />
          </div>
          <div class="course__step">
            <img :src="imgUrls.pt90_cur_subtitle_6" class="subtitle" />
            <img :src="imgUrls.pt90_cur_img_6" class="subimg" />
          </div>
        </div>
      </div>
    </section>
    <section class="course_learn" id="course_learn">
      <div class="inner">
        <img :src="imgUrls.pt90_allin_1" class="allin_img" />
        <img :src="imgUrls.pt90_allin_3" class="allin_desc" />
        <img :src="imgUrls.pt90_allin_4" class="allin_desc" />
        <img :src="imgUrls.pt90_allin_5" class="allin_img" />
      </div>
    </section>
    <section class="effect" id="effect">
      <div class="inner">
        <img class="effect1" :src="imgUrls.pt90_effect_img_1" />
        <div class="card__wrapper">
          <div class="card">
            <ul>
              <li>
                <span class="counter--word">{{ priceComma(parseInt(counter.word)) }}</span>
                단어
              </li>
              <li>
                <span class="counter--sentence">{{ priceComma(parseInt(counter.sentence)) }}</span>
                문장
              </li>
              <li>
                <span class="counter--time">{{ priceComma(parseInt(counter.time)) }}</span>
                분 발화시간
              </li>
            </ul>
            <p>
              90일 동안, 이렇게나 입밖으로
              <br />
              영어를 말할 수 있게 됩니다.
            </p>
          </div>
          <img class="card__background" :src="imgUrls.pt90_mid_07_1" />
        </div>
        <img class="effect2" :src="imgUrls.pt90_mid_07_2" />
      </div>
    </section>
    <section class="course__chart">
      <div class="inner">
        <img :src="imgUrls.pt90_chart_1" class="chart_title" />
        <img :src="imgUrls.pt90_chart_2" class="chart_img" />
        <p class="chart_desc">수업 만족도</p>
        <img :src="imgUrls.pt90_chart_3" class="chart_img" />
        <p class="chart_desc">완수 후 재구매율</p>
        <img :src="imgUrls.pt90_chart_4" class="chart_img" />
        <p class="chart_desc">평균 레벨 변화율</p>
        <img :src="imgUrls.pt90_chart_5" class="chart_img" />
        <p class="chart_desc">완강 후 환불률</p>
        <img :src="imgUrls.pt90_chart_6" class="chart_title" />
      </div>
    </section>
    <section class="course__guarantee">
      <div class="guarantee__main">
        <div class="inner">
          <img :src="imgUrls.pt90_certificate" class="certificate_img" />
        </div>
      </div>
      <!-- 수료조건 -->
      <div class="course__complete" id="course__complete">
        <div class="inner_2">
          <img :src="imgUrls.pt90_com_title" class="cur_title" />
          <img :src="imgUrls.pt90_com_img_1" class="cur_image_1" />
          <img :src="imgUrls.pt90_com_img_2" class="cur_image_2" />
          <img :src="imgUrls.pt90_com_img_3" class="cur_image_2" />
          <div v-if="appView" class="toCounselingbtn" @click="moveToCounseling('mid_package_advice_1')" />
        </div>
      </div>
    </section>
    <section class="differ" id="differ">
      <div class="inner">
        <img :src="imgUrls.pt90_vs_01" />
      </div>
    </section>
    <section class="tutors">
      <div class="inner">
        <img :src="imgUrls.pt90_tutor_title" class="tutor_title" />
        <div class="slides">
          <swiper
            :loop="true"
            :space-between="20"
            :slidesPerView="1"
            :speed="500"
            :autoplay="{ dalay: '200', disableOnInteraction: false }"
            :pagination="{ clickable: true }"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
          >
            <swiper-slide>
              <img :src="imgUrls.pt90_tutor_01" />
            </swiper-slide>
            <swiper-slide>
              <img :src="imgUrls.pt90_tutor_02" />
            </swiper-slide>
            <swiper-slide>
              <img :src="imgUrls.pt90_tutor_03" />
            </swiper-slide>
            <swiper-slide>
              <img :src="imgUrls.pt90_tutor_04" />
            </swiper-slide>
            <swiper-slide>
              <img :src="imgUrls.pt90_tutor_05" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <section class="award">
      <div class="inner">
        <img :src="imgUrls.pt90_award" class="award_img" />
      </div>
    </section>
    <section class="videos">
      <div class="inner">
        <div class="videos__title">
          <img :src="imgUrls.pt90_video_title" />
        </div>
        <div class="videos__thumbnail">
          <VideoCard videoId="TorTumHoR1w"></VideoCard>
          <div class="videos__subtitle">튜터링 4년차 간호사 김경아 님</div>
        </div>
        <div class="videos__thumbnail">
          <VideoCard videoId="JRipgatcheI"></VideoCard>
          <div class="videos__subtitle">튜터링 1년차 대학생 이소현 님</div>
        </div>
        <div class="videos__thumbnail">
          <VideoCard videoId="iomYIbeBZOw"></VideoCard>
          <div class="videos__subtitle">튜터링 2년차 영어선생님 윤지은 님</div>
        </div>
      </div>
    </section>
    <section class="reviews">
      <div class="inner">
        <img class="reviews__title" :src="imgUrls.pt90_review_title" />
        <div class="reviews__slide">
          <swiper
            :slides-per-view="3"
            :space-between="20"
            :centeredSlides="true"
            :slideToClickedSlide="true"
            :initialSlide="1"
            style="min-width: 855px !important"
          >
            <swiper-slide class="reviewcard">
              <p class="reviewcard__title">튜터링으로 영어 스피킹 레벨업 ㅠㅠ</p>
              <p class="reviewcard__body">
                영어로 자기소개도 잘 못했던, 영어 1도 못하는 찐 초보였는데 튜터링 덕분에 자신감도 생겼고, 튜터 님들이
                활발하게 수업 진행해주셔서 저도 적극적 으로 참여하게 돼요. 영어 실력이 발전되는게 느 껴지니깐 자꾸 하게
                되요!
              </p>
              <div class="reviewcard__writer">
                <div class="writer__name">Zett</div>
                <div class="writer__score">
                  <div class="score__stars" />
                  <div class="score__text">5.0</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="reviewcard">
              <p class="reviewcard__title">아무때나 편하게 수업 할 수 있어서 좋아요~</p>
              <p class="reviewcard__body">
                다른 전화영어는 정해진 시간에 전화가 오는 방식 이라서 항상 시간을 비워두는게 은근히 귀찮고 잘 빼먹게
                되더라구요. 튜터링은 제가 시간이 있을 때 들어가서 수업 들으니깐 부담스럽지 않고 너무 좋았어요.
              </p>
              <div class="reviewcard__writer">
                <div class="writer__name">Linda</div>
                <div class="writer__score">
                  <div class="score__stars" />
                  <div class="score__text">5.0</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="reviewcard">
              <p class="reviewcard__title">수업 내용 녹음본으로 복습하니 엄청 편해요</p>
              <p class="reviewcard__body">
                튜터와 수업을 하고 나면, 열심히 말하고 듣느라 정신 없을 때도 있는데, 튜터 피드백은 물론이고, 수업한
                내용을 다시 들어볼 수 있는 게 참 좋았습 니다. 놓친 부분도 확인하고 스스로 복습하니 실 력도 더 향상
                되는거 같아요!
              </p>
              <div class="reviewcard__writer">
                <div class="writer__name">Vincent</div>
                <div class="writer__score">
                  <div class="score__stars" />
                  <div class="score__text">5.0</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <!-- 가격안내 -->
    <section class="course__price" id="course__price" v-if="!isPromotionLoading">
      <div class="inner">
        <div class="title">
          <p class="title_text" :style="{ 'font-size': 'calc(20px + 0.5vw)', 'margin-bottom': '5px' }">
            국민 가격으로 실력 향상!
          </p>
          <p class="title_text" :style="{ 'font-size': 'calc(28px + 0.5vw)' }">작심 패키지</p>
        </div>
        <div class="price">
          <div class="price_select">선택 1</div>
          <div class="price_title">{{ promotionData.plans[2].title }}</div>
          <div class="price_content">
            <p
              class="price_content_text"
              :style="{ 'font-size': 'calc(16px + 0.5vw)', color: '#afabb7', 'text-decoration': 'line-through' }"
            >
              정가 {{ promotionData.plans[2].origin_money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
            </p>
            <div class="price_container">
              <p class="price_content_discount">{{ promotionData.plans[2].discount }}%</p>
              <p class="price_discount">
                {{ promotionData.plans[2].money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              </p>
            </div>
            <p class="price_content_text" :style="{ 'font-size': 'calc(36px + 0.5vw)', 'font-weight': '700' }">
              <span :style="{ 'font-size': 'calc(26px + 0.5vw)', 'font-weight': '700' }">월</span>
              {{ promotionData.plans[2].moneyPerMonth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              <span class="price_month">({{ promotionData.plans[2].month_cnt }}개월)</span>
            </p>
          </div>
        </div>
        <div class="price">
          <div class="price_select">선택 2</div>
          <div class="price_title">{{ promotionData.plans[1].title }}</div>
          <div class="price_content">
            <p
              class="price_content_text"
              :style="{ 'font-size': 'calc(16px + 0.5vw)', color: '#afabb7', 'text-decoration': 'line-through' }"
            >
              정가 {{ promotionData.plans[1].origin_money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
            </p>
            <div class="price_container">
              <p class="price_content_discount">{{ promotionData.plans[1].discount }}%</p>
              <p class="price_discount">
                {{ promotionData.plans[1].money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              </p>
            </div>
            <p class="price_content_text" :style="{ 'font-size': 'calc(36px + 0.5vw)', 'font-weight': '700' }">
              <span :style="{ 'font-size': 'calc(26px + 0.5vw)', 'font-weight': '700' }">월</span>
              {{ promotionData.plans[1].moneyPerMonth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              <span class="price_month">({{ promotionData.plans[1].month_cnt }}개월)</span>
            </p>
          </div>
        </div>
        <div class="price">
          <div class="price_select">선택 3</div>
          <div class="price_content">
            <p
              class="price_content_text"
              :style="{ 'font-size': 'calc(16px + 0.5vw)', color: '#afabb7', 'text-decoration': 'line-through' }"
            >
              정가 {{ promotionData.plans[0].origin_money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
            </p>
            <div class="price_container">
              <p class="price_content_discount">{{ promotionData.plans[0].discount }}%</p>
              <p class="price_discount">
                {{ promotionData.plans[0].money.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              </p>
            </div>
            <p class="price_content_text" :style="{ 'font-size': 'calc(36px + 0.5vw)', 'font-weight': '700' }">
              <span :style="{ 'font-size': 'calc(26px + 0.5vw)', 'font-weight': '700' }">월</span>
              {{ promotionData.plans[0].moneyPerMonth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}원
              <span class="price_month">({{ promotionData.plans[0].month_cnt }}개월)</span>
            </p>
          </div>
        </div>
        <p
          :style="{
            'font-size': 'calc(28px + 0.5vw)',
            color: '#ffffff',
            'font-weight': '700',
            'text-align': 'center',
            'margin-bottom': '22px',
          }"
        >
          만약!
          <br />
          작심 90일 커리큘럼을
          <br />
          100% 수료했음에도
          <br />
          실력이 늘지 않았다면?
        </p>
        <p
          :style="{
            'font-size': 'calc(28px + 0.5vw)',
            color: '#04D9FF',
            'font-weight': '700',
            'text-align': 'center',
            'margin-bottom': '60px',
          }"
        >
          회원님이 결제하신
          <br />
          수강료의 2배를 보상합니다.
        </p>
        <p
          :style="{ 'font-size': 'calc(12px + 0.5vw)', color: '#04D9FF', 'font-weight': '400', 'text-align': 'center' }"
        >
          실력 향상 보증 제도는 튜터링의 자신감의 표현입니다.
        </p>
        <p
          :style="{
            'font-size': 'calc(12px + 0.5vw)',
            color: '#ffffff',
            'font-weight': '400',
            'text-align': 'center',
            'margin-bottom': '50px',
          }"
        >
          언어학자와 뇌과학자들의 연구 성과물을 기반으로,
          <br />
          NO.1 영어 학습 서비스를 만들어온 튜터링의 노하우가
          <br />
          집약되어 준비한 이 커리큘럼을 이수하시면,
          <br />
          어떠한 학습자도 실력이 늘 수 밖에 없습니다.
          <br />
          그만큼 자신 있게 준비된 학습 프로그램입니다.
        </p>
      </div>
    </section>
    <section class="package" id="package">
      <div class="package__info">
        <div class="inner">
          <img :src="imgUrls.pt90_recommend" class="recommend_img" />
          <div v-if="appView" class="toCounselingbtn" @click="moveToCounseling('mid_package_advice_2')" />
        </div>
      </div>
    </section>

    <!-- 쿠폰 다운로드 영역 -->
    <!-- <div class="coupon-download-contaienr">
      <button class="coupon-download-box button" @click="showCouponModal" type="button">
        <div class="box">
          <div class="left-img" />
          <div class="text">
            <p class="desc">튜터링 할인 페스타</p>
            <p class="title">할인쿠폰 4종</p>
          </div>
        </div>
        <div class="download-img" />
        <div class="download-done">
              다운
              <br />
              완료
            </div>
      </button>
      <p class="desc">자세한 쿠폰 사용 안내는 수강권 유의사항을 참고해 주세요.</p>
    </div> -->

    <div v-if="isCouponClicked">
      <div class="layer_comm">
        <PopupToast :isPopup="isPopup"><p v-html="cuponPopupMessage"></p></PopupToast>
        <div class="dimmed"></div>
        <div class="inner_wrapper">
          <div class="inner_layer">
            <div class="coupon_title">
              즉시 사용 가능한
              <br />
              추가 할인 쿠폰
            </div>
            <div class="coupon__container" id="togo1">
              <div
                v-for="(coupon, index) in couponData?.coupon?.data?.coupons"
                :key="index"
                @click="!hasCouponIdxArray(coupon.idx) && !coupon.hasCoupon && postCouponDownload(coupon.idx, index)"
                :class="[
                  { 'coupon coupon100000': coupon.dc_money === 100000 },
                  { 'coupon coupon50000': coupon.dc_money === 50000 },
                  { 'coupon coupon30000': coupon.dc_money === 30000 },
                  { 'coupon coupon10000': coupon.dc_money === 10000 },
                  { 'is-download': coupon.hasCoupon || hasCouponIdxArray(coupon.idx) },
                ]"
              ></div>
              <button v-if="hasCouponIdx.length < 4" class="coupon_button" @click="postCouponDownloadAll()">
                쿠폰 한 번에 받기
              </button>
              <button
                v-if="hasCouponIdx.length >= 4"
                class="coupon_button_enabled"
                @click="postCouponDownloadAll()"
                disabled
              >
                다운로드 완료
              </button>
            </div>
          </div>
          <div class="coupon_notice">
            <div class="notice_title">
              <p>쿠폰 유의 사항</p>
            </div>
            <div class="notice_desc">
              <p>· 쿠폰 다운로드 후 사용이 가능합니다.</p>
              <p>· 일부 수강권에서는 쿠폰 사용이 불가합니다.</p>
            </div>
          </div>
          <div class="extra_wrapper">
            <button class="extra_button" @click="showCouponModal">
              <img
                class="close_icon"
                src="//res.tutoring.co.kr/res/images/img/plans/ic_cp_modal_close.png"
                alt="close"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 수강증 발급 안내 -->
    <section class="focus-certificate">
      <div class="promotion-certificate">
        <h2 class="heading">수강증 상시 발급 가능</h2>
        <p class="description">수강증, 출석확인증, 영수증 상시 발급 가능</p>
        <div class="certificate__tip">
          직장인들을 위한 SPEC UP!
          <br />
          사내 교육지원비로 알뜰하게!
        </div>
        <button @click="openSample" type="button" class="button modal__certificate__open">
          증빙서류 샘플 상세보기
        </button>
      </div>
      <div class="promotion-guide">
        <h2 class="heading">증빙 서류 발급 안내</h2>
        <ul class="guide__list">
          <li class="list__item">
            수강증, 출석 확인증, 영수증은 튜터링 앱 메인 화면 우측 하단 [마이튜터링] &gt; [고객센터] &gt; [증빙 서류
            발급]에서 신청 가능합니다.
          </li>
          <li class="list__item">정해진 학습 기간 내에 수업을 진행해야만 출석으로 인정합니다.</li>
          <li class="list__item">증빙서류의 경우 내용(수강권명, 금액)등은 임의 수정이 불가합니다.</li>
          <li class="list__item">
            발급 신청 시 입력한 이메일 주소로 자동 발급됩니다.(기타 문의사항은 [고객센터 &gt; 1:1 채팅 문의]로 연락
            주시기 바랍니다.)
          </li>
        </ul>
      </div>
    </section>

    <!-- 증명서류 샘플 상세보기 클릭시 나오는 부분 -->
    <div
      v-if="isSampleClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal__certificate" v-click-outside="openSample">
        <div class="common-modal__content">
          <h1 class="heading">튜터링 증빙서류 샘플</h1>
          <p class="description">
            수강증, 출석확인증, 영수증 상시 발급 가능
            <br />
            연 수강권의 경우 매월 수강권 발급이 가능합니다.
          </p>
          <ul class="list">
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img01.png" alt="" />
              - 수강증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img02.png" alt="" />
              - 출석 확인증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img03.png" alt="" />
              - 영수증 증빙서류 샘플 -
            </li>
          </ul>
        </div>
        <button type="button" class="modal__certificate__close" @click="openSample">닫기</button>
      </div>
    </div>

    <!-- 하단 유의 사항  -->
    <section class="promotion-notice">
      <div class="notice__item">
        <h2 class="heading">
          <button type="button" class="heading__button">튜터링 영어 유의사항 안내</button>
        </h2>
        <div class="notice" v-for="(item, index) in promotionData.planGuide" :key="index">
          <h2 class="notice__title">{{ item.title }}</h2>
          <ul class="notice__list">
            <li v-for="(text, i) in sortNoticeText[index]" :key="i" class="list__item">
              <p v-html="text"></p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!--맨아래 구매유도 버튼(계속 보이는거)-->
    <section class="challenge-cta" :class="{ 'ani-bottom-up': bottomCtaUp }" id="challengeCta">
      <div class="challenge-cta__wrap" :class="{ sheetOn: isMobileBottomSheetOpen }">
        <div class="wrapper">
          <!--이 버튼은 시트 올라왔을 때 보이는 버튼-->
          <button v-if="isMobileBottomSheetOpen" @click="purchasePlan()" type="button" class="bottom_tab">
            혜택 확인하기
          </button>
          <!--이 버튼은 시트 올라오기 전 버튼-->
          <div class="btnwrap" v-if="!isMobileBottomSheetOpen">
            <!-- pc 버전 -->
            <!-- <button
              type="button"
              class="cta__button pc-layer"
              v-scroll-to="{
                el: '#tutoringContent',
                duration: 200,
                easing: 'linear',
                offset: -exceptBnrScroll,
                x: false,
                y: true,
                onStart: () => {
                  trackingUserAction('mid_package_footer');
                },
              }"
            >
              수강권 둘러보기
            </button> -->
            <!-- pc 이하 버전 -->
            <button @click="goToApply" type="button" class="fixed__button">수강권 둘러보기</button>
          </div>
        </div>
      </div>

      <!-- 모바일 크기일 때 구매 모달창 -->
      <!-- {/* is-show 넣으면 올라옵니다. */} -->
      <article class="sheet" :class="{ 'is-show': isMobileBottomSheetOpen }" id="bottomUpSheet">
        <div class="sheet__wrap">
          <div class="btn" @click="closeBottomSheetTab(), trackingUserAction('mid_package_footer_close')"></div>
          <Pr90DaysMiddleOptions
            @update="updateOptionIdx"
            :promotionData="promotionData"
            :choicedOptionIdx="choicedOptionIdx"
            :choicedOptionData="choicedOptionData"
            :isRecommendIdx="isRecommendIdx"
          />
        </div>
        <div class="dim" @click="closeBottomSheetTab(), trackingUserAction('mid_package_footer_close')"></div>
      </article>
    </section>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { storage, native } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';
import Pr90DaysMiddleOptions from './component/Pr90DaysMiddleOptions.vue';
import VideoCard from './component/VideoCard.vue';
import Accordion from './component/Accordion.vue';
import PopupToast from '../../components/PopupToast.vue';
// import CouponBox from './component/CouponBox.vue';

import 'swiper/swiper-bundle.css';

export default {
  name: 'Pr90daysMiddle',
  components: {
    AppNav,
    Swiper,
    SwiperSlide,
    Pr90DaysMiddleOptions,
    VideoCard,
    Accordion,
    PopupToast,
    // CouponBox,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        PC_pt90_mid_thum01: '//res.tutoring.co.kr/res/images/evt/202203/pt90/PC_pt90_mid_thum01.jpg',
        pt90_mid_title_img: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_title_img.gif',
        pt90_mid_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_why.png',
        pt90_mid_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_professor.png',
        pt90_mid_03: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2about.png',
        pt90_mid_04_title: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_title_04.png',
        pt90_mid_04_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_04_1.png',
        pt90_mid_04_2: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_04_2.png',
        pt90_mid_04_3_gif: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_3.gif',
        pt90_mid_04_3_desc: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_05_4.png',
        pt90_mid_04_4: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_04_4.png',
        pt90_mid_04_5: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_04_5.png',
        pt90_mid_05_title: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_title_05.png',
        pt90_mid_05_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_05_1.png',

        // 탑 배너 1200px
        pt90_banner_thumb_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_01.png',
        pt90_banner_thumb_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_02.png',
        pt90_banner_thumb_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_03.png',
        pt90_banner_thumb_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_04.png',
        pt90_banner_thumb_img_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_05.png',
        pt90_banner_thumb_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_06.png',
        pt90_banner_thumb_img_8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_w1600_rolling_08.png',
        // 탑 배너
        pt90_banner_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_01.png',
        pt90_banner_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_02.png',
        pt90_banner_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_03.png',
        pt90_banner_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_04.png',
        pt90_banner_img_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_05.png',
        pt90_banner_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_06.png',
        pt90_banner_img_8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_rolling_08.png',
        pt90_banner_desc: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2top_sum.png',
        // 커리큘럼
        pt90_cur_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_mtl_01.png',
        pt90_cur_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_02.png',
        pt90_cur_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_03.png',
        pt90_cur_img_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_04.gif',
        pt90_cur_img_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_special_06.png',
        pt90_cur_subtitle_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_01.png',
        pt90_cur_subtitle_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_02.png',
        pt90_cur_subtitle_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_03.png',
        pt90_cur_subtitle_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_04.png',
        pt90_cur_subtitle_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_stl_06.png',

        // 수료조건
        pt90_com_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_inform.png',
        pt90_com_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_90day_02.png',
        pt90_com_img_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_180day_02.png',
        pt90_com_img_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_inform_qa.png',
        pt90_price: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_pricing.png',
        // ALL in one
        pt90_allin_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_01.png',
        pt90_allin_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_02.png',
        pt90_allin_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_allinone_03.png',
        pt90_allin_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_step.png',
        pt90_allin_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2chart_01.gif',
        // 차트
        pt90_chart_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_chart_01.png',
        pt90_chart_2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_01.gif',
        pt90_chart_3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_02.gif',
        pt90_chart_4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_05.gif',
        pt90_chart_5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_chart_04.gif',
        pt90_chart_6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_chart_02.png',
        pt90_recommend: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_recomm.png',
        pt90_award: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_awards_2023.png',
        pt90_video_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_review_01.png',

        // 튜터
        pt90_certificate: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_certificate.png',
        pt90_vs_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_1vs_01.png',
        pt90_vs_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2vs_02.png',
        pt90_tutor_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_tutor.png',
        pt90_tutor_01: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro01.png',
        pt90_tutor_02: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro02.png',
        pt90_tutor_03: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro03.png',
        pt90_tutor_04: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro04.png',
        pt90_tutor_05: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tutor_pro05.png',
        pt90_review_title: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_review_02.png',
        pt90_effect_img_1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_tl_effect.png',

        course1: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_01.png',
        course2: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_02.png',
        course3: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_03.png',
        course4: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_04.png',
        course5: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_05.png',
        course6: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_06.png',
        course7: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_07.png',
        course8: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_08.png',
        course9: '//res.tutoring.co.kr/res/images/img/plans/challenge/img_2course_09.png',
        pt90_mid_07_title: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_title_07.png',
        pt90_mid_07_1: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_07_1.png',
        pt90_mid_07_2: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_mid_07_2.png',
      },

      courseInfos: {
        course1: {
          title: '말하는 두려움 깨기',
          paragraph: [
            'Dear diary : 표현편',
            '표현 정복하기 : Do',
            '표현 정복하기 : Have',
            '짧은 글짓기 1',
            '원어민 코스프레 : 인사 & 소개편',
            '원어민 코스프레 : 상태편',
            '짧은 글짓기 2',
            '오늘의 표현 시즌 1-1',
            '오늘의 표현 시즌 1-2',
            '짧은 글짓기 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 1',
          ],
        },
        course2: {
          title: '자연스러운 문장으로 말하기 2',
          paragraph: [
            '짧은 글짓기 4',
            '원어민 코스프레 : 날씨 & 계절편 1',
            '원어민 코스프레 : 장소편 1',
            '짧은 글짓기 5',
            '표현 정복하기 : Get',
            '표현 정복하기 : Take',
            '짧은 글짓기 6',
            '원어민 코스프레 : 성격편 1',
            '원어민 코스프레 : 감정편 1',
            '오늘의 표현 시즌 1-3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 2',
          ],
        },
        course3: {
          title: '목적과 상황에 맞는 말하기',
          paragraph: [
            '상황별 표현 : 공항편',
            '병원에서 통하는 영어 : 내과편',
            '원어민 코스프레 : 맛 표현편',
            '상황별 표현 : 여행 중 돌발상황편',
            '원어민 코스프레: 다툼 & 화해편',
            '병원에서 통하는 영어 : 이비인후과편',
            '상황별 표현 : 대중교통편',
            '원어민 코스프레: 부탁 & 감사편',
            '상황별 표현 : 레스토랑편',
            '허니문 허니팁',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 3',
          ],
        },
        course4: {
          title: '정확한 의사소통을 위하여',
          paragraph: [
            '연결의 달인, 접속사 마스터',
            '한국인이 잘 틀리는 문법',
            '끝나지 않은 이야기, 완료시제로 말해봐요!',
            'Konglish? Enghlish! 1',
            'If I were... : 무인도에서 살아남기!',
            'Common Mistakes',
            '수동태 완전 정복',
            'Konglish English! 2',
            '좀 더 길게 말하고 싶을 땐, 관계대명사!',
            'Konglish? English! 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 4',
          ],
        },
        course5: {
          title: '문제 해결 능력 UP!',
          paragraph: [
            '단어 게임',
            'Make a colorful story',
            '상황별 표현 : 여행 리허설편',
            '주문을 말해봐',
            '내 인상의 책 : 중급편',
            '스피치 영어 : 비욘세편',
            '내가 더 좋아하는 것 말하기',
            'Do you believe in...? : Fantasy편',
            '영어 문장 만들기 : 스토리 제작소편',
            '밸런스 게임 : 가족 모임편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 5',
          ],
        },
        course6: {
          title: '손안에서 떠나는 어학연수',
          paragraph: [
            '튜터와의 일상대화 : 대학생편',
            '해외연수 3개월 과정 1',
            '해외연수 3개월 과정 2',
            '원어민 코스프레 : 동의 & 비동의편',
            '해외연수 3개월 과정 3',
            '해외연수 3개월 과정 4',
            '원어민 코스프레 : 길 설명하기편',
            '해외연수 3개월 과정 5',
            '해외연수 3개월 과정 6',
            '원어민 코스프레 : 의견 말하기편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 6',
          ],
        },
        course7: {
          title: '스피킹 시험 대비를 위하여',
          paragraph: [
            '그림 설명하기',
            '토익 스피킹 : 지문 읽기편',
            '토익 스피킹 : 사진 묘사편',
            '토익 스피킹 : 질문 응답편',
            '토익 스피킹 : 정보 제공편',
            '토익 스피킹 : 의견 제시편',
            '오픽 모의테스트 1 (3-4)',
            '오픽 모의테스트 1 (5-6)',
            '오픽 모의테스트 2 (3-4)',
            '오픽 모의테스트 2 (5-6)',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 7',
          ],
        },
        course8: {
          title: '비즈니스 영어 집중 코스',
          paragraph: [
            '튜터와의 일상대화 : 직장인편',
            '비지니스 : 일상편 1',
            '비지니스 : 전화편',
            '비지니스 : 미팅편 1',
            '비지니스 : 발표편 1',
            '비지니스 : 프로젝트 관리편 1',
            '비지니스 : 회사 물품편',
            '비지니스 : 호스팅편 1',
            '비지니스 : 출장편 1',
            '원어민 코스프레 : 여행중 협상 표현편',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 8',
          ],
        },
        course9: {
          title: '일상 대화의 달인',
          paragraph: [
            '튜터와의 일상대화 : 일상편',
            '알록달록 색깔들',
            '수고했어 오늘도',
            '스피치 영어 : BTS 방탄소년단편',
            '영어로 대화하기 : 줄임말 편',
            '프리토킹',
            '쇼퍼홀릭',
            '100가지 주제로 말하기 1',
            '100가지 주제로 말하기 2',
            '100가지 주제로 말하기 3',
            '(셀프스터디) 에세이 쓰기 : 중급 코스 9',
          ],
        },
      },
      promotionData: { plans: [{ cp_idx: 0 }] },

      isSampleClicked: false,
      isPurchaseClicked: false,

      purchaseErrorMessage: '',
      purchaseErrorSubMessage: '',
      purchaseErrorType: '',
      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 6904 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {},
      isRecommendIdx: 0,
      curTab: 1,
      tabsFix: false,
      tabsFixTop: '0px',
      bottomCtaUp: false,

      counter: {
        word: 0,
        sentence: 0,
        time: 0,
      },

      isMobileBottomSheetOpen: false,

      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',

      isCouponClicked: false,
      couponData: { plans: [{ cp_idx: 0 }] },
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',
      hasCouponIdx: [],
      isDownload: true,

      isPromotionLoading: true,
    };
  },

  created() {
    this.getPromotionData();
    this.getCouponData();
    this.goHome();
  },
  mounted() {
    // if (!inappTools.isInApp()) {
    // 	this.$router.go(-1);
    // }
    this.onBottomSheetOn();
    this.onTabFixHandlerOn();
    this.onTabChangeHandler();
    this.initBackToPromotionFromCounselingFlag();
    this.setCounterAni();
    this.setPurchaeDateInfo();
    if (localStorage.getItem('key')) {
      this.setTrackingUserAction();
    }
    // window.C.NativeTool.onBackPressed = () => {
    // 	native.close();
    // };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2`;
      }
    },
    setTrackingUserAction(): void {
      const device = inappTools.isInApp() ? 'APP' : 'WEB';
      api.post('/app/users/setTracking', {
        event_name: 'detail',
        page_name: 'pr90daysmiddle',
        device_type: `${device}`,
      });
    },
    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },
    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    showCouponModal(): void {
      this.isCouponClicked = !this.isCouponClicked;
      if (this.isCouponClicked) {
        this.trackingUserAction(`middle_readytogo_coupon_down`);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.pageYOffset}`;
        document.body.style.width = '100%';
      } else {
        this.trackingUserAction(`middle_readytogo_Coupon_close`);
        document.body.style.overflow = 'unset';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    },
    async getCouponData(): Promise<any> {
      this.couponData = await api.get('/app/etc/promotion/365low').then((response: AxiosResponse) => response.data);

      this.couponData.coupon.data.coupons.forEach((coupon) => {
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      if (this.hasCouponIdx.length >= 4) {
        this.isDownload = !this.isDownload;
      }
    },
    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      this.trackingUserAction(`middle_readytogo_Coupon_${index + 1}`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      this.trackingUserAction(`middle_readytogo_Coupon_all`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        if (this.hasCouponIdx.length >= 4) {
          this.isDownload = !this.isDownload;
          return;
        }
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: '1053,1054,1055,1056' })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(1053, 1054, 1055, 1056);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    openPurchaseModal(): void {
      this.isPurchaseClicked = true;
    },
    closePurchaseModal(): void {
      this.isPurchaseClicked = false;

      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.$router.push('/');
      }
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days_mid',
        })
        .then((response: AxiosResponse) => response.data);
      // const newPlan = [...this.promotionData.plans]
      const RecommendPlan = this.promotionData.plans.filter((data) => data.isRecommend);
      const RecommendIndex = this.promotionData.plans.findIndex((data) => data.isRecommend);
      this.isRecommendIdx = RecommendIndex;
      this.choicedOptionIdx = RecommendPlan[0].cp_idx;
      this.choicedOptionData = { ...RecommendPlan[0] };
      this.isPromotionLoading = false;
    },

    openBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
      // document.body.style.overflow = "hidden";
      // document.body.style.overscrollBehavior = "none";
    },

    goToApply() {
      this.trackingUserAction('mid_package_footer');
      this.$router.push({ name: 'planAllPass', params: { prevPage: 'Pr90daysMiddle' } });
    },
    closeBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
      // document.body.style.overflow = "unset";
      // document.body.style.overscrollBehavior = "";
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    goPurchasePage() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      this.isPurchaseClicked = false;
      if (inappTools.isInApp()) {
        native.close();
      } else {
        const url = `https://${document.location.host}/home/order?&ptype=Kr&courseIdx=undefined`;
        window.location.href = url;
      }
    },
    purchasePlan() {
      const copyLoggedInUser = storage.session.get('loggedInUser');
      if (copyLoggedInUser) {
        const {
          lms: { s_level: userLevel },
          goods: { show2022_90Days: userHasNoTicket },
        } = copyLoggedInUser;
        if (!userHasNoTicket) {
          this.puchaseErrorType = 'hasTicket';
          this.purchaseErrorMessage = '이 상품은 현재 수업 진행중인 수강권이 없는 회원을 위한 상품입니다.';
          this.openPurchaseModal();
        } else if (Number(userLevel) !== 7) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>중급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 아닙니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else {
          this.trackingUserAction('mid_package_footer_payment');
          this.goPurchasePage();
        }
      } else {
        this.pushToLogin();
      }
    },
    initBackToPromotionFromCounselingFlag() {
      // 페이지 마운트 되었을 때 프로모션에서 상담예약으로 넘어갔는지 여부를 지워버리는 함수.
      // 만약 이전에 스크롤된 기록이 있다면, 그 위치로 스크롤 넘겨버림.
      const lastScrollOffset = storage.session.get('pr90DaysLastScrollOffset');
      if (lastScrollOffset) {
        setTimeout(() => {
          window.scrollTo(0, lastScrollOffset);
        });
      }
      storage.session.remove('backToPromotionFromCounselingFlag');
      storage.session.remove('pr90DaysLastScrollOffset');
    },
    moveToCounseling(name) {
      // 상담예약 버튼 눌렀을 때 그 인앱 페이지로 연결시키는 함수 (프로모션에서 넘어갔음을 플래그로 저장)
      this.trackingUserAction(name);
      // 인앱일 때 (웹일 때는 버튼 자체가 없음)
      if (inappTools.isInApp()) {
        const lastScrollOffset = document.documentElement.scrollTop;
        storage.session.set('routeAfterWebViewClosed', '/app/crm?ref=more');
        storage.session.set('backToPromotionUrlFromCounseling', 'Pr90dayMiddle');
        storage.session.set('pr90DaysLastScrollOffset', lastScrollOffset);
        native.close();
      }
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },

    onChangeTab(idx) {
      this.curTab = idx;
    },
    onTabChangeHandler() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffsetTabOne = document.getElementById('course__info')?.offsetTop;
        const endOffsetTabOne = document.getElementById('course_learn')?.offsetTop;
        const startOffsetTabTwo = document.getElementById('course__complete')?.offsetTop;
        const endOffsetTabTwo = document.getElementById('differ')?.offsetTop;
        const startOffsetTabThree = document.getElementById('course__price')?.offsetTop;
        const endOffsetTabThree = document.getElementById('package')?.offsetTop;

        if (
          startOffsetTabOne &&
          endOffsetTabOne &&
          startOffsetTabTwo &&
          endOffsetTabTwo &&
          startOffsetTabThree &&
          endOffsetTabThree
        ) {
          if (scrollLocation > startOffsetTabOne - 95 && scrollLocation < endOffsetTabOne - 95) {
            this.curTab = 1;
          } else if (scrollLocation > startOffsetTabTwo - 95 && scrollLocation < endOffsetTabTwo - 95) {
            this.curTab = 2;
          } else if (scrollLocation > startOffsetTabThree - 95 && scrollLocation < endOffsetTabThree - 95) {
            this.curTab = 3;
          }
        }
      });
    },
    onTabFixHandlerOn() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffset = document.getElementById('course__info')?.offsetTop;
        const endOffset = document.getElementById('effect')?.offsetTop;

        if (startOffset && endOffset) {
          if (inappTools.isInApp()) {
            if (scrollLocation > startOffset - 95) {
              this.tabsFix = true;
              this.tabsFixTop = '35px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹
          else if (window.innerWidth > 1199) {
            if (scrollLocation > startOffset - 150) {
              this.tabsFix = true;
              this.tabsFixTop = '136px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹 이하
          else if (scrollLocation > startOffset - 80) {
            this.tabsFix = true;
            this.tabsFixTop = '71px';
          } else {
            this.tabsFix = false;
          }
        }
      });
    },
    pushToVideo() {
      if (inappTools.isInApp()) {
        native.openWebView('https://www.youtube.com/embed/Z9FzlPSenyg', '작심 90일 패키지');
      } else {
        window.open('https://www.youtube.com/embed/Z9FzlPSenyg');
      }
    },
    guaranteeSectionOffset() {
      if (window.innerWidth > 1199) {
        return 195;
      }
      return 95;
    },
    courseTabChoiceSectionOffset() {
      if (window.innerWidth > 1199) {
        return 140;
      }
      return 65;
    },
    doCounterAni() {
      const EndTime = 800;
      const wordCounterSpeed = 50670 / EndTime;
      const sentenceCounterSpeed = 5670 / EndTime;
      const timeCounterSpeed = 765 / EndTime;

      const wordCounter = setInterval(() => {
        this.counter.word += wordCounterSpeed;
        if (this.counter.word >= 50670) {
          this.counter.word = 50670;
          clearInterval(wordCounter);
        }
      }, 1);

      const sentenceCounter = setInterval(() => {
        this.counter.sentence += sentenceCounterSpeed;
        if (this.counter.sentence >= 5670) {
          this.counter.sentence = 5670;
          clearInterval(sentenceCounter);
        }
      }, 1);

      const timeCounter = setInterval(() => {
        this.counter.time += timeCounterSpeed;
        if (this.counter.time > 765) {
          this.counter.time = 765;
          clearInterval(timeCounter);
        }
      }, 1);
    },
    setCounterAni() {
      const $counterSection = document.querySelector('#effect');
      if (window.IntersectionObserver && $counterSection) {
        const interSectionObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 감지대상이 교차영역에 진입 할 경우
              this.doCounterAni();
              observer.unobserve($counterSection); // 카운터 시작 이후로 관찰 필요 없다.
            }
          });
        });
        interSectionObserver.observe($counterSection);
      } else {
        this.counter.word = 50670;
        this.counter.sentence = 5670;
        this.counter.time = 765;
      }
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
  },

  computed: {
    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/g, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],
      };
    },
  },
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.promotion {
  overflow: hidden;
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toCounselingbtn {
  margin-top: 40px;
  width: 220px;
  height: 64px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/challenge/btn_coach.png');
  background-size: cover;
  cursor: pointer;
  border-radius: 40px;
}
.toGuaranteeSectionBtn {
  width: 220px;
  height: 64px;
  background-image: url('//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_cta2.png');
  background-size: cover;
  cursor: pointer;
  border-radius: 40px;
}
.modal--purchase {
  width: 350px;
  .common-modal__description--small {
    margin-top: 18px;
  }
}

.promotion__banner {
  text-align: center;
}
/*프로모션 헤더(웹)*/
.promotion__header {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: 130px;
  @media (max-width: 1199px) {
    & {
      display: none;
    }
  }

  & .thumbnail {
    width: 570px;
    height: 570px;
    margin-right: 46px;

    .slides {
      width: 100%;
      .swiper-container {
        padding-bottom: 22px;
      }
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  & .info {
    max-width: 583px;
    width: 100%;
    & .textarea {
      text-align: left;
      line-height: 1;

      & .btn {
        height: 68px;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: #6d11d6;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
      }
    }
    & .textarea__title {
      & h1 {
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 16px;
      }
      & h2 {
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 25px;
        border-bottom: 1px solid #ddd;
      }
    }
    & .textarea__option {
      padding-top: 21px;
    }
  }
}
.swiper-slide {
  justify-content: center;
}

.why {
  display: flex;
  justify-content: center;
  background-color: #121214;

  & .inner {
    margin: 20px 0;
  }

  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.correct {
  display: flex;
  justify-content: center;
  background-color: #04d9ff;

  & .inner {
    margin: 20px 0;
  }

  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.banner__course {
  display: flex;
  justify-content: center;
  background-color: #009df5;

  & .inner {
    position: relative;
    margin: 80px 0 100px;
  }

  & .img1 {
    max-width: 50%;
    min-width: 360px;
  }
  & .blur {
    position: absolute;
    bottom: -100px;
    width: 100vw;
    height: 500px;
    background-position-x: 53%;
    background-position-y: 6%;
    background-image: url('https://res.tutoring.co.kr/res/images/img/plans/challenge/img_light_1.png');
  }
}
.course_learn {
  display: flex;
  justify-content: center;
  background-color: #04d9ff;

  & .inner {
    margin: 80px 0 100px;
    .allin_img {
      max-width: 50%;
      min-width: 360px;
    }
    .allin_video {
      margin-top: 30px;
      max-width: 50%;
      min-width: 360px;
    }
    .allin_desc {
      max-width: 50%;
      min-width: 360px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.course__chart {
  display: flex;
  justify-content: center;
  background-color: #ffffff;

  & .inner {
    margin: 80px 0 100px;

    .chart_title {
      max-width: 40%;
      min-width: 380px;
      margin-top: 60px;
    }
    .chart_img {
      max-width: 30%;
      min-width: 300px;
      margin-top: 60px;
    }
    .chart_desc {
      text-align: center;
      margin-top: 20px;
      font-size: 35px;
      font-weight: 700;
    }
  }
}

.course__guarantee {
  background-color: #300a61;
  & .guarantee__main {
    display: flex;
    justify-content: center;

    & .inner {
      flex-direction: column;
      align-items: center;

      .certificate_img {
        max-width: 30%;
        min-width: 360px;
      }
    }
  }
  .course__complete {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f3f3f3;
    .inner_2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 80px 0 100px;
      img.cur_title {
        max-width: 40%;
        min-width: 380px;
        margin-bottom: 10px;
      }
      & img.cur_image_1 {
        max-width: 60%;
        min-width: 380px;
        margin-top: 20px;
      }
      & img.cur_image_2 {
        max-width: 60%;
        min-width: 380px;
        margin-top: 20px;
      }
    }
  }
}
.courses__container {
  & .course__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;

    & img {
      margin: 100px 0 60px;
    }

    & .course__tab {
      display: flex;
      justify-content: center;
      width: 100%;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

      & li {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        width: 164px;
        height: 56px;
        border-radius: 8px 8px 0 0;
        color: #afabb7;
        cursor: pointer;
      }

      & li.active {
        background-color: #fff;
        color: #6d11d6;
        .tabs {
          text-align: center;
          border-bottom: 3px solid #6d11d6;
          width: 61%;
          margin: auto;
          line-height: 52px;
        }
      }
    }
    & .course__tab.fixed {
      position: fixed;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 10;
      box-shadow: 0px 2px 10px #121214;
      background-color: #fff;

      & li {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        width: 164px;
        height: 56px;
        border-radius: 8px 8px 0 0;
        color: #afabb7;
        cursor: pointer;
      }

      & li.active {
        background-color: #fff;
        color: #6d11d6;
        .tabs {
          text-align: center;
          border-bottom: 3px solid #6d11d6;
          width: 61%;
          margin: auto;
          line-height: 52px;
        }
      }
    }
  }

  & .course__info {
    display: flex;
    justify-content: center;
    background-color: #121214;
    .inner {
      flex-direction: column;
      align-items: center;
      margin: 80px 0 100px;
      max-width: 30%;
      min-width: 400px;
      .main_img {
        width: 100%;
      }
      & .course__step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 80px;
        width: 100%;
        & img.subtitle {
          width: 100%;
        }
        & img.subimg {
          max-width: 380px;
          margin-top: 25px;
          border-radius: 10px;
        }
        & .subimg2 {
          margin-top: 25px;
          width: 330px;
          border-radius: 10px;
        }

        & .course__special {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;

          & .course__slide__wrapper {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            overflow: hidden;
            min-width: 2502px;
            & .course__slide {
              width: 2502px;
              min-width: 2502px;

              & .slide__card {
                background-color: #fefefe;
                width: 262px;
                min-width: 262px;
                cursor: pointer;
                border-radius: 20px;
                overflow: hidden;
                @media only screen and (max-width: 768px) {
                  -webkit-box-sizing: border-box;
                }

                & img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.effect {
  display: flex;
  justify-content: center;
  background-color: #300a61;
  overflow: hidden;
  & .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    & img.effect1 {
      max-width: 50%;
      min-width: 360px;
    }
    & > p {
      margin-top: 8px;
      font-weight: 200;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
    & .card__wrapper {
      width: 453px;
      position: relative;
      & img.card__background {
        width: 100%;
        height: 507px;
      }
      & .card {
        position: absolute;
        top: 67px;
        left: 50%;
        margin-left: calc(300px / -2);
        width: 300px;
        height: 373px;

        & ul {
          font-weight: 900;
          font-size: 36px;
          line-height: 42px;
          color: #121214;
          text-shadow: -0.3px 0 #121214, 0 0.3px #121214, 0.3px 0 #121214, 0 -0.3px #121214;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          & li {
            margin-top: 20px;
            padding-bottom: 3px;

            & span {
              display: inline-block;
              text-align: end;
              color: #5d04d9;
              text-shadow: -0.3px 0 #5d04d9, 0 0.3px #5d04d9, 0.3px 0 #5d04d9, 0 -0.3px #5d04d9;

              &.counter--word {
                width: 120px;
              }
              &.counter--sentence {
                width: 100px;
              }
              &.counter--time {
                width: 65px;
                margin-right: -8px;
              }
            }
          }
        }

        & p {
          margin-top: 42px;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          color: #300a61;
        }
      }
    }
    & img.effect2 {
      max-width: 40%;
      min-width: 300px;
    }
  }
}
.differ {
  display: flex;
  justify-content: center;
  background-color: #333333;
  padding: 100px 0 40px;
  & img {
    max-width: 50%;
    min-width: 360px;
  }
}
.tutors {
  display: flex;
  justify-content: center;
  background-color: #300a61;
  & .inner {
    margin: 100px 0 70px;
    img.tutor_title {
      max-width: 40%;
      min-width: 380px;
      margin-bottom: 30px;
    }
    .slides {
      width: 360px;
      .swiper-container {
        padding-bottom: 27px;
      }
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.course__price {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #371469;
  padding: 50px 0px;
  .inner {
    flex-direction: column;
    align-items: center;
    .title {
      padding: 50px 40px;
      &_text {
        margin-bottom: 5px;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      &_select {
        background: #04d9ff;
        width: 25%;
        padding: 10px 0px;
        border-radius: 40px;
        color: #371469;
        font-size: calc(16px + 0.5vw);
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      &_title {
        font-size: calc(14px + 0.5vw);
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 20px;
      }
      &_container {
        display: flex;
        align-items: center;
        margin: 10px 0;
        justify-content: center;
      }
      &_content {
        position: relative;
        background: #ffffff;
        border-radius: 30px;
        padding: 40px 50px;
        text-align: center;
        &_discount {
          padding: calc(2px + 0.1vw) 8px;
          font-size: calc(14px + 0.2vw);
          font-weight: 600;
          border-radius: 8px;
          background: #f24b4b;
          color: #ffffff;
          margin-right: 8px;
        }
      }
      &_discount {
        font-size: calc(16px + 0.2vw);
        font-weight: 600;
      }
      &_month {
        font-size: calc(14px + 0.2vw);
      }
    }
  }
}
.award {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 20px 0;
  overflow: hidden;
  .award_img {
    max-width: 50%;
    min-width: 360px;
  }
}
.videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 100px 0;
  overflow: hidden;
  & .videos__title {
    max-width: 40%;
    min-width: 380px;
    margin-bottom: 10px;
  }
  & .videos__thumbnail {
    margin-top: 20px;
  }
  & .videos__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5d04d9;
  padding: 100px 100px;
  overflow: hidden;
  & .reviews__title {
    max-width: 40%;
    min-width: 380px;
  }

  & .reviews__slide {
    width: 855px;
    margin-top: 40px;

    & .reviewcard {
      width: 275px;
      min-width: 275px;
      height: 200px;
      background: #ffffff;
      border-radius: 15px;
      padding: 25px;
      box-sizing: border-box;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        -webkit-box-sizing: border-box;
      }
    }

    & .reviewcard__title {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.594203px;
      color: #333333;
    }

    & .reviewcard__body {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.594203px;
      color: #555555;
      margin-top: 13px;
    }

    & .reviewcard__writer {
      display: flex;
      justify-content: space-between;
      margin-top: 13px;

      & .writer__name {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.594203px;
        color: #333333;
      }

      & .writer__score {
        display: flex;
        align-items: center;
        & .score__stars {
          width: 82px;
          height: 16px;
          background: url(//res.tutoring.co.kr/res/images/common/ico_comm.png) no-repeat -124px -84px;
          background-size: 220px 188px;
          background-position: -38px -84px;
        }
        & .score__text {
          margin-left: 5px;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          color: #333333;
        }
      }
    }
  }

  & ul {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.package {
  & .package__info {
    background-color: #5d04d9;
    padding: 20px 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .recommend_img {
      max-width: 50%;
      min-width: 360px;
    }
  }
}
/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  bottom: 0;
  & .challenge-cta__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    height: 84px;
    @media (max-width: 1199px) {
      & {
        height: 98px;
        padding: 0 20px;
      }
    }
    &.sheetOn {
      background: #fff;
    }
    & .wrapper {
      width: 100%;
      max-width: 672px;

      & button {
        display: flex;
        width: 100%;
        height: 50px;
        background: #6d11d6;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        @media (max-width: 1199px) {
          & {
            font-size: 15px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

.sheet {
  position: absolute;
  bottom: -1000px;
  width: 100%;
  color: #000;
  transition: all 0.4s;
  z-index: -1;

  & .dim {
    display: none;
  }

  &.is-show {
    bottom: 84px;
    transition: all 0.4s;
    & .dim {
      display: block;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      background-blend-mode: multiply;
      z-index: -1;
    }
  }

  & .sheet__wrap {
    border-radius: 10px 10px 0 0;
    background: #fff;
    padding: 0 20px;

    & .option {
      height: 70vh;
      overflow-y: scroll;
    }

    & .btn {
      position: relative;
      height: 50px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 30%;
        right: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg) translateY(-50%);
        content: '';
      }
    }
  }
}

// 쿠폰 다운로드
.coupon-download-contaienr {
  background-color: #fff;
  padding: 20px 20px 50px 20px;
}
.coupon-download-contaienr .coupon-download-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #5d04d9;
  border-radius: 20px;
  height: 96px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
@media (max-width: 767px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 530px;
  }
}
.coupon-download-contaienr .coupon-download-box .box {
  display: flex;
  align-items: center;
}
.coupon-download-contaienr .coupon-download-box .box .left-img {
  width: 50px;
  height: 41px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/ic_plan_coupon.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50px 41px;
  margin-right: 20px;
  margin-left: 25px;
}
.coupon-download-contaienr .coupon-download-box .text .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.5px;
  color: #afabb7;
  margin-bottom: 5px;
}
.coupon-download-contaienr .coupon-download-box .text .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #121214;
}
.coupon-download-contaienr .coupon-download-box .download-img {
  width: 46px;
  height: 46px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/btn_down.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 46px 46px;
  margin-right: 25px;
}
.coupon-download-contaienr .coupon-download-box .download-done {
  width: 96px;
  height: 100%;
  background: #efe6fb;
  border-radius: 0 20px 20px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(93, 4, 217, 0.5);
  padding: 32px 35px;
}
.coupon-download-contaienr .desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #afabb7;
}
.layer_comm {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layer_comm .dimmed {
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.3;
}

.layer_comm .inner_wrapper {
  position: absolute;
  top: 54%;
  right: 0;
  left: 0;
  width: 348px;
  /* height: 100%; */
  margin: 0 auto;
  transform: translateY(-50%);
}

.layer_comm .inner_layer {
  display: flex;
  top: inherit;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 54vh;
  /* height: 478px; */
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 30px 0;
  margin: 0 auto;
  position: initial;
}
.layer_comm .coupon_title {
  width: 42%;
  margin: 0 auto 10px auto;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 25px;
  font-size: 20px;
}
.coupon__container {
  overflow: auto;
  width: 100%;
}

.coupon__container .coupon {
  width: 258px;
  height: 102px;
  margin: 0 auto 8px auto;
  margin-bottom: 8px;
  cursor: pointer;
}
.coupon.coupon100000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_10_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon100000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_10_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_5_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_5_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_3_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_3_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_1_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_1_off.png) no-repeat 50%;
  background-size: contain;
}

.coupon_button {
  width: 258px;
  background: #c021c3;
  border-radius: 10px;
  padding: 12px 16px;
  color: #ffff;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_button_enabled {
  width: 258px;
  background: #f1f0f5;
  border-radius: 10px;
  padding: 12px 16px;
  color: #d6d3dd;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_notice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  /* position: absolute; */
  background-color: #f1f1f1;
  border-radius: 0 0 20px 20px;
  padding: 20px 24px;
}
.coupon_notice .notice_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.coupon_notice .notice_desc {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #666666;
}

.extra_wrapper {
  padding: 10px 0;
  text-align: center;
  /* position: relative;
	top: 740px; */
}
.extra_wrapper .extra_button {
  color: #fff;
  background-color: #121214;
  border-radius: 25px;
  font-size: 25px;
  padding: 2px 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.extra_wrapper .extra_button .close_icon {
  width: 16px;
  height: 16px;
}
.coupon-wrapper {
  margin-bottom: -48px;
  padding: 20px;
  background-color: #f5f5f5;
}
</style>
