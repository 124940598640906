
import vClickOutside from 'click-outside-vue3';
import promotionMixins from '../../../common/mixins/promotion';
import vImageLazyLoading from '../../../common/directives/ImgLazyLoading';

export default {
  name: 'Pr90DaysMiddleOptions',
  mixins: [promotionMixins],
  props: {
    promotionData: Object,
    choicedOptionIdx: Number,
    choicedOptionData: Object,
    isRecommendIdx: Number,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  data() {
    return {
      copyChoicedOptionIdx: this.choicedOptionIdx,
      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',
      currentOptionServes: [],
      currentOptionInstallment: '12',
      choiceTitle: '수강권을 선택해주세요.',
      choiceIndex: 0,
      choiceRecommend: false,
      imgUrls: {
        ic_arrow_down: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_16px.png',
        ic_arrow_up: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_up_16px.png',
      },
      isOpenCollapse: false,
    };
  },
  mounted() {
    this.setPurchaeDateInfo();
    this.setCurrentOptionServes();
    this.choiceIndex = this.isRecommendIdx
  },
  beforeUpdate() {
    this.copyChoicedOptionIdx = this.choicedOptionIdx;
    this.choiceRecommend = this.choicedOptionData?.isRecommend;
  },
  methods: {
    sendChoicedOptionId(option, index, recommend) {
      switch (option) {
        case 6904:
          this.trackingUserAction('90package_mid_footer_select90_Global');
          break;
        case 6903:
          this.trackingUserAction('90package_mid_footer_select60_Global');
          break;
        case 6902:
          this.trackingUserAction('90package_mid_footer_select180_Global');
          break;
        case 6916:
          this.trackingUserAction('90package_mid_footer_select90_Native');
          break;
        case 6917:
          this.trackingUserAction('90package_mid_footer_select60_Native');
          break;
        case 6905:
          this.trackingUserAction('90package_mid_footer_select180_Native');
          break;
        default:
          break;
      }
      this.copyChoicedOptionIdx = option;
      this.setCurrentOptionServes();
      this.$emit('update', option);
      this.choiceIndex = index;
      console.log(this.choiceIndex)
      this.choiceRecommend = recommend
      this.isOpenCollapse = false;
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
    replacedTitle(title: string) {
      if (title) {
        const newTitle = title.replace('[중급전용]', '');
        return newTitle;
      }
      return title;
    },
    // 기존 라디오 박스 형태가 UI의 문제로 인해 드롭박스 형태로 변경
    // 이 과정에서 현재 페이지는 [중급전용] 페이지가 확실하므로 [중급전용] 문구를 삭제하는 함수 생성
    // 이후 작업에서 해당 문구가 사라지거나 다른 문구를 삭제해야하는 상황이 생길시 수정 가능
    parserTitle(title: string, num: number) {
      console.log(title)
      if (!title) {
        return "수강권이 없습니다"
      }
      const newTitle = title.replace('[중급전용]', '');
      return `${num + 1}. ${newTitle}`
    },
    toggleCollapse(): void {
      this.isOpenCollapse = !this.isOpenCollapse;
    },
    setCurrentOptionServes() {
      switch (this.copyChoicedOptionIdx) {
        case 6904:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 주 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6903:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 60회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 30회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6902:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6916:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 주 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6917:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 60회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 30회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        case 6905:
          this.currentOptionServes = [
            '단기 집중 원어민 1:1 학습 수강권 90회',
            '밀도있는 1:1 학습 독려 및 케어 시스템 2주당 1회 제공',
            '1:1 원어민 첨삭 수강권 45회 제공',
            '튜터링 AI 학습 무제한 제공',
            '튜터링 콘텐츠 LAB이 엄선한 커리큘럼에 따른 체계적인 주차별 수업',
          ];
          break;
        default:
          break;
      }
    },
  },
  computed: {
    Plans() {
      const Plans = [...this.promotionData.plans]
      return Plans;
    },
  },
};
