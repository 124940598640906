<template>
  <div>
    <iframe
      class="video"
      :width="width"
      :height="height"
      :src="`https://www.youtube.com/embed/${videoId}`"
      title=" 원어민 | 영어회화 | 집에서 | 영어공부 | (4:5)"
      frameborder="0"
      allow="accelerometer; autoplay; 
            clipboard-write; encrypted-media;
            gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script lang="ts">
export default {
  name: 'VideoCard',
  props: {
    width: {
      type: Number,
      default: 320,
    },
    height: {
      type: Number,
      default: 175,
    },
    videoId: {
      type: String,
      required: true,
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
.video {
  border-radius: 20px;

  @media (min-width: 768px) {
    & {
      width: 460px;
      height: 260px;
    }
  }
}
</style>
